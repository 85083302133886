// React
import React, {FC, lazy} from 'react'

// Third-party
import {Switch, Redirect} from 'react-router'
import {retry} from './componentLoader'

// Typescript
import {IRoute} from './IRoute'

// App
const MainRouteWithSubRoutes = lazy(() => retry(() => import('./MainRouteWithSubRoutes')))
const RouteWithSubRoutes = lazy(() => retry(() => import('./RouteWithSubRoutes')))

interface IProps {
    routes: IRoute[]
}

const Router: FC<IProps> = ({routes}) => {
    return (
        <Switch>
            <MainRouteWithSubRoutes path="/" name="Главная" hide={true} exact={true} fallback={null} />
            {routes && routes.map((route: IRoute) => <RouteWithSubRoutes key={route.path} {...route} />)}
            <Redirect to="/" />
        </Switch>
    )
}

export default Router
