import {ICategoriesActionTypes, ICategoriesState} from '../../models/categories'

const initialState: ICategoriesState = {
    categories: [],
    category: null,
    stock: null,
    activeCategory: null,
    activeCategoryType: null,
    loading: true,
    error: null,
}

export const categoriesReducer: (
    state: ICategoriesState,
    action: ICategoriesActionTypes
) => void = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CATEGORIES_START':
            return {
                ...state,
                loading: true,
                error: null,
            }
        case 'FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                loading: false,
                categories: action.payload,
                error: null,
            }
        case 'FETCH_CATEGORIES_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case 'SET_ACTIVE_CATEGORY_TYPE':
            return {
                ...state,
                activeCategoryType: action.payload,
            }
        case 'SET_ACTIVE_CATEGORY': {
            let activeCategory =
                state.categories.find(({id}) => id === action.payload) || null
            if (!action.payload) {
                activeCategory = null
            }
            return {
                ...state,
                activeCategory,
            }
        }
        case 'DELETE_CATEGORY_SUCCESS':
            return {
                ...state,
                loading: false,
                categories: state.categories.filter(
                    ({id}) => id !== action.payload
                ),
                error: null,
            }
        case 'FETCH_CATEGORY_START':
        case 'CREATE_CATEGORY_START':
        case 'UPDATE_CATEGORY_START':
        case 'FETCH_STOCK_START':
            return {
                ...state,
                loading: true,
                error: null,
            }
        case 'FETCH_CATEGORY_SUCCESS':
        case 'CREATE_CATEGORY_SUCCESS':
        case 'UPDATE_CATEGORY_SUCCESS':
            return {
                ...state,
                loading: false,
                category: action.payload,
                error: null,
            }
        case 'FETCH_CATEGORY_ERROR':
        case 'CREATE_CATEGORY_ERROR':
        case 'UPDATE_CATEGORY_ERROR':
        case 'FETCH_STOCK_ERROR':
        case 'CHANGE_STOCK_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case 'CHANGE_STOCK_SUCCESS':
        case 'FETCH_STOCK_SUCCESS':
            return {
                ...state,
                stock: action.payload,
                loading: false,
                error: null,
            }
        case 'DELETE_CATEGORY_PRODUCTS_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}
