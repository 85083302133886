// React
import React from 'react'

// Styles
import classes from './AdminLoader.module.css'

type Props = {
    title?: string
}
const AdminLoader: React.FC<Props> = (props) => {
    const {title = ''} = props

    return <div className={`${classes.wrapper} ${title ? classes.pb : ''}`}>
        <div className={classes.loader}/>
        {title
            ? <p className={classes.loading}>{title}<span>.</span><span>.</span><span>.</span></p>
            : null
        }
    </div>
}

export default AdminLoader
