import {IProduct, IProductsActionTypes, IProductsState} from '../../models/products'
import {removeDuplicateProducts} from '../../utils'
import {
    FETCH_PRODUCTS_ADMIN_SUCCESS,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCT_SUCCESS,
    CREATE_PRODUCT_SUCCESS,
    SET_PRODUCT_FILTERS_START,
    SET_PRODUCT_FILTERS_SUCCESS,
    CLEAR_PRODUCT_FILTERS,
    SET_PRODUCT_FILTERS_DISABLED,
    FETCH_LABELS_START,
    FETCH_PRODUCT_START,
    FETCH_PRODUCTS_START,
    CREATE_PRODUCT_START,
    DELETE_LABELS_ERROR,
    FETCH_LABELS_ERROR,
    UPDATE_LABELS_ERROR,
    CREATE_LABELS_ERROR,
    FETCH_PRODUCT_ERROR,
    CREATE_PRODUCT_ERROR,
    FETCH_PRODUCTS_ERROR,
    DELETE_LABELS_SUCCESS,
    FETCH_LABELS_SUCCESS,
    CREATE_LABELS_SUCCESS,
    UPDATE_LABELS_SUCCESS,
    REPLICATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_SUCCESS
} from '../actions/actionTypes'

const initialState: IProductsState = {
    products: [],
    hasMore: true,
    adminProducts: [],
    totalProducts: 0,
    product: null,
    labels: [],
    filtersLoading: true,
    labelsLoading: true,
    loading: false,
    error: null,
    productsFilters: null,
    productsDisabled: false
}

const updateProductInState = (
    products: IProduct[],
    payload: {id: number; quantity: number; decrease: boolean}
): IProduct[] => {
    const itemIndex = products.findIndex(item => item.id === payload.id)
    if (itemIndex === -1) return products
    const quantity = payload.decrease ? products[itemIndex].quantity - payload.quantity : payload.quantity
    if (quantity === 0) return [...products.slice(0, itemIndex), ...products.slice(itemIndex + 1)]
    const product = {...products[itemIndex], quantity}
    return [...products.slice(0, itemIndex), product, ...products.slice(itemIndex + 1)]
}

export const productsReducer: (state: IProductsState, action: IProductsActionTypes) => void = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_PRODUCTS_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                adminProducts: action.payload.products,
                totalProducts: action.payload.count,
                error: null
            }
        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                hasMore: action.payload.length !== 0,
                products:
                    action.append === true
                        ? action.payload.length === 0
                            ? state.products
                            : removeDuplicateProducts([...state.products, ...action.payload])
                        : action.payload,
                productsDisabled: false,
                error: null
            }
        case FETCH_PRODUCT_SUCCESS:
        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                product: action.payload,
                error: null
            }
        case SET_PRODUCT_FILTERS_START:
            return {
                ...state,
                filtersLoading: true,
                error: null
            }
        case SET_PRODUCT_FILTERS_SUCCESS:
            return {
                ...state,
                productsFilters: action.payload,
                filtersLoading: false,
                productsDisabled: false,
                error: null
            }
        case CLEAR_PRODUCT_FILTERS:
            return {
                ...state,
                productsFilters: {}
            }
        case SET_PRODUCT_FILTERS_DISABLED:
            return {
                ...state,
                productsDisabled: true
            }
        case FETCH_LABELS_START:
            return {
                ...state,
                labelsLoading: true,
                error: null
            }
        case FETCH_PRODUCT_START:
        case FETCH_PRODUCTS_START:
        case CREATE_PRODUCT_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case DELETE_LABELS_ERROR:
        case FETCH_LABELS_ERROR:
        case UPDATE_LABELS_ERROR:
        case CREATE_LABELS_ERROR:
            return {
                ...state,
                labelsLoading: false,
                error: action.payload
            }
        case FETCH_PRODUCT_ERROR:
        case CREATE_PRODUCT_ERROR:
        case FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_LABELS_SUCCESS:
        case FETCH_LABELS_SUCCESS:
        case CREATE_LABELS_SUCCESS:
        case UPDATE_LABELS_SUCCESS:
            return {
                ...state,
                labelsLoading: false,
                labels: action.payload,
                error: null
            }
        case REPLICATE_PRODUCT_SUCCESS:
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: updateProductInState(state.products, action.payload)
            }
        default:
            return state
    }
}
