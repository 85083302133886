// React
import React from 'react'

type Props = {
    closeToast: () => void
}
const CloseButton: React.FC<Props> = (props) => {
    const {closeToast} = props

    return <button className='Toastify__close-button' onClick={closeToast}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.22903 5.99899L10.9528 1.27523C11.0157 1.21226 11.0157 1.11019 10.9528 1.04721C10.8898 0.984262 10.7877 0.984262 10.7248 1.04721L6.00101 5.77097L1.27725 1.04721C1.21319 0.985351 1.11111 0.987124 1.04923 1.05118C0.988877 1.11367 0.988877 1.21275 1.04923 1.27523L5.77299 5.99899L1.04923 10.7227C0.985168 10.7846 0.983395 10.8867 1.04528 10.9508C1.10716 11.0148 1.20924 11.0166 1.2733 10.9547C1.27465 10.9534 1.27596 10.9521 1.27725 10.9508L6.00101 6.22701L10.7248 10.9508C10.7888 11.0127 10.8909 11.0109 10.9528 10.9468C11.0132 10.8843 11.0132 10.7852 10.9528 10.7227L6.22903 5.99899Z"
                stroke="#646464" strokeWidth="0.6"/>
        </svg>
    </button>
}

export default CloseButton
