import {IOrdersActionTypes, IOrdersState} from '../../models/orders'

const initialState: IOrdersState = {
    orders: [],
    order: null,
    editOrderId: null,
    totalOrders: 0,
    amountOrders: 0,
    adminOrders: [],
    loading: false,
    isOrdered: false,
    error: null
}

export const ordersReducer: (state: IOrdersState, action: IOrdersActionTypes) => void =
    (state = initialState, action) => {
        switch (action.type) {
            case 'FETCH_ORDERS_START':
            case 'FETCH_ADMIN_ORDERS_START':
            case 'FETCH_ORDER_START':
                return {
                    ...state, loading: true, error: null
                }
            case 'FETCH_ORDERS_SUCCESS':
                return {
                    ...state, loading: false, orders: action.payload, error: null
                }
            case 'FETCH_ORDERS_ERROR':
            case 'FETCH_ADMIN_ORDERS_ERROR':
            case 'FETCH_ORDER_ERROR':
                return {
                    ...state, loading: false, error: action.payload
                }
            case 'CREATE_ORDER_START':
                return {
                    ...state, loading: true, isOrdered: false, error: null
                }
            case 'CREATE_ORDER_SUCCESS':
                return {
                    ...state, loading: false, isOrdered: true, error: null
                }
            case 'CREATE_ORDER_ERROR':
                return {
                    ...state, loading: false, error: action.payload, isOrdered: false
                }
            case 'FETCH_ADMIN_ORDERS_SUCCESS':
                return {
                    ...state,
                    loading: false,
                    adminOrders: action.payload.orders,
                    totalOrders: action.payload.count,
                    amountOrders: action.payload.amount,
                    error: null
                }
            case 'CANCEL_ORDER_SUCCESS': {
                const newOrders = [...state.orders]
                newOrders[newOrders.findIndex(el => el.id === action.payload.id)] = action.payload
                return {
                    ...state, loading: false, orders: [...newOrders], error: null
                }
            }
            case 'FETCH_ORDER_SUCCESS':
                return {
                    ...state, loading: false, order: action.payload, error: null
                }
            case 'SET_EDIT_ORDER_ID':
                return {
                    ...state, editOrderId: action.payload
                }
            case 'CHANGE_ORDER_CART_SUCCESS': {
                return {
                    ...state, loading: false, order: action.payload
                }
            }
            default:
                return state
        }
    }
