import {ICheckoutActionTypes, ICheckoutState} from '../../models/checkout'

const initialState: ICheckoutState = {
    checkout: null,
    loading: true,
    error: null
}

export const checkoutReducer: (state: ICheckoutState, action: ICheckoutActionTypes) => void = (
    state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CHECKOUT_SUCCESS':
            return {
                ...state, loading: false, checkout: action.payload, error: null
            }
        case 'FETCH_CHECKOUT_ERROR':
            return {
                ...state, loading: false, error: action.payload
            }
        case 'CREATE_CHECKOUT_START':
        case 'FETCH_CHECKOUT_START':
        case 'CREATE_CHECKOUT_SUCCESS':
            return {
                ...state, loading: true, error: null
            }
        case 'CREATE_CHECKOUT_ERROR':
            return {
                ...state, loading: false, error: action.payload
            }
        default:
            return state
    }
}
