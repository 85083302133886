// Third-party
import {combineReducers} from 'redux'

// App
import {categoriesReducer} from './categories'
import {subCategoriesReducer} from './subCategories'
import {productsReducer} from './products'
import {cartReducer} from './cart'
import {checkoutReducer} from './checkout'
import {ordersReducer} from './orders'
import {usersReducer} from './users'
import {staticReducer} from './static'
import {selectedSectionReducer} from './slugs'
import {sectionReducer} from './section'
import {sectionCategoriesReducer} from './sectionCategories'

export default combineReducers({
    usersState: usersReducer,
    productsState: productsReducer,
    categoriesState: categoriesReducer,
    subCategoriesState: subCategoriesReducer,
    cartState: cartReducer,
    checkoutState: checkoutReducer,
    ordersState: ordersReducer,
    staticState: staticReducer,
    selectedSectionState: selectedSectionReducer,
    sectionState: sectionReducer,
    sectionCategoriesState: sectionCategoriesReducer,
})
