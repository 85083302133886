import React, {ReactText, useCallback, useEffect, useState} from 'react'
import {IContentProduct} from './models/carts'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {history} from './App'
import {saveAs} from 'file-saver'
import {IProduct} from './models/products'
import {useDispatch} from 'react-redux'
import {
    IMPORT_FROM_EXCEL_ERROR,
    IMPORT_FROM_EXCEL_START,
    IMPORT_FROM_EXCEL_SUCCESS,
} from './store/actions/actionTypes'

interface IResponse {
    created: IProduct[]
    updated: IProduct[]
    errors: {number: string[]}
}

/**
 * Image formatter
 * @param src
 * @param alt
 * @param className
 * @param placeholder
 */
export const imgFormatter: (
    src: string,
    alt?: string,
    className?: string,
    placeholder?: string
) => JSX.Element = (
    src,
    alt,
    className = '',
    placeholder = '/images/placeholder-product-image.png'
) => {
    return <img className={className} src={src || placeholder} alt={alt} />
}

/**
 * Add days to date
 * @param date
 * @param days
 */
export const addDays: (date: Date, days: number) => Date = (date, days) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

/**
 * Substring string by value and length
 * @param value
 * @param length
 */
export const substringOut: (
    value: string | undefined,
    length: number
) => string = (value: string, length: number) => {
    if (value && value.length > length) {
        return `${value.substring(0, length)}...`
    } else {
        return value
    }
}

/**
 * Convert timestamp to m.d.Y by timestamp
 * @param timestamp
 * @param isShowTime
 */
export const timeConverter: (
    timestamp: number | null,
    isShowTime?: boolean
) => string = (timestamp, isShowTime = false) => {
    if (!timestamp) {
        return ''
    }
    const a = new Date(timestamp * 1000)
    let month = String(a.getMonth() + 1)
    const year = a.getFullYear()
    let date = String(a.getDate())

    let hour = String(a.getHours())
    let min = String(a.getMinutes())

    date = String(date).length === 1 ? `0${date}` : date
    month = String(month).length === 1 ? `0${month}` : month
    hour = String(hour).length === 1 ? `0${hour}` : hour
    min = String(min).length === 1 ? `0${min}` : min

    if (isShowTime) {
        return `${date}.${month}.${year} в ${hour}:${min}`
    }
    return `${date}.${month}.${year}`
}

/**
 * Convert timestamp to Date
 * @param timestamp
 */
export const timestampConvertToDate: (
    timestamp: number | string | null | undefined
) => Date = (timestamp) => {
    if (!timestamp) {
        return new Date()
    }

    return new Date(+timestamp * 1000)
}

/**
 * Create notification message content by title and description
 * @param title
 * @param desc
 */
export const createNotyMsg: (title: string, desc: string) => JSX.Element = (
    title: string,
    desc: string
) => {
    return (
        <>
            <p className="Toastify__title">{title}</p>
            <p className="Toastify__desc">{desc}</p>
        </>
    )
}

export const getSubCartTotalPrice: (
    contentProducts: IContentProduct[]
) => number = (contentProducts) => {
    return contentProducts.reduce(
        (acc, contentProduct) =>
            acc + contentProduct.quantity * contentProduct.product.price,
        0
    )
}

const getErrorMessage = (error) => {
    if (!error.response?.data.message) return error.message
    const match = error.response?.data.message.match(/DETAIL:\s+(.+)\s+\(SQL/)
    if (match) return match[1]
    return error.message
}

/**
 * Toast error handler
 * @param error
 */
export const errorHandler: (error: AxiosError) => void = (error) => {
    let message = error.message
    switch (error.response?.status) {
        case 401:
            localStorage.removeItem('access_token')
            localStorage.removeItem('crocus_user')
            pushToHistory('/')
            message = ''
            break
        case 400:
        case 402:
        case 403:
            message = error.response.data.message
            break
        case 404:
            message =
                error.response?.data?.message ||
                'Упс, такая страница не существует'
            break
        case 410:
            window.location.reload()
            message = ''
            break
        case 422:
            Object.values(error.response.data.errors).map(
                (values: any) => (message = values.join(', '))
            )
            break
        case 423:
            message = error.response.data.message
            pushToHistory('/')
            break
        default:
            message = getErrorMessage(error)
    }
    message && toast.error(message)
    return message
}

/**
 * Convert bytes to kb, mb etc
 * @param bytes
 * @param decimals
 */
export const formatBytes: (
    bytes: number | string,
    decimals: number
) => string = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(+bytes) / Math.log(k))

    return parseFloat((+bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Return value as string or '-'
 * @param value
 */
export const formatValue: (
    value: string | number | null | undefined
) => string = (value) => {
    if (!value) {
        return '-'
    }
    return value.toString()
}

export const exportToExcel: (type: string) => void = (type) => {
    const search = new URLSearchParams(history.location.search)
    search.delete('page')
    axios
        .get(
            process.env.REACT_APP_API_URL +
                `/api/${type}/export${
                    search.toString() ? `/?${search.toString()}` : ''
                }`,
            {responseType: 'blob'}
        )
        .then(({data}) => {
            const blob = new Blob([data], {
                type: 'application/vnd.ms-excel;charset=utf-8',
            })
            toast.success('Файл excel успешно сгенерирован')
            saveAs(blob, `${type}.xlsx`)
        })
        .catch((error: AxiosError) => {
            errorHandler(error)
        })
}

export const useImportFromExcel = (
    type: string,
    setResponse?: (data: IResponse) => void,
    setError?: (error: AxiosError) => void
): {
    importFromExcel: (
        acceptedFiles: Blob[],
        optionType?: '1' | '2' | '3' | ''
    ) => void
} => {
    const dispatch = useDispatch()

    const importFromExcel: (
        acceptedFiles: Blob[],
        optionType?: '1' | '2' | '3' | ''
    ) => void = useCallback(
        (acceptedFiles, optionType) => {
            console.dir(acceptedFiles)
            if (!acceptedFiles || !acceptedFiles.length) return
            dispatch({type: IMPORT_FROM_EXCEL_START})
            const formData = new FormData()
            formData.append('file', acceptedFiles[0])
            if (type === 'products' && optionType) {
                formData.append('type', optionType)
            }
            axios
                .post(
                    process.env.REACT_APP_API_URL + `/api/${type}/import`,
                    formData
                )
                .then(({data}: AxiosResponse<IResponse>) => {
                    dispatch({type: IMPORT_FROM_EXCEL_SUCCESS})
                    setResponse && setResponse(data)
                    toast.success('Пользователи успешно импортированы')
                })
                .catch((error: AxiosError) => {
                    const message = errorHandler(error)
                    dispatch({type: IMPORT_FROM_EXCEL_ERROR, payload: message})
                    setError && setError(error)
                })
        },
        [dispatch, setError, setResponse, type]
    )

    return {importFromExcel}
}

/**
 *  Append to search params by search-key and data
 * @param key
 * @param data
 */
export const appendToSearch: (
    key: string,
    data: string | number
) => string | null = (key, data) => {
    if (!key) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    let search = new URLSearchParams(history.location.search)
    setTimeout(() => {
        search = new URLSearchParams(history.location.search)
        search.set(key, data.toString())
        search.sort()
        console.log('appendToSearch: ', search.toString())
        if (search.toString() !== oldSearch.toString()) {
            pushToHistory(search.toString())
        }
    }, 0)
    return search.toString()
}

/**
 *  Append to search params by search-keys array and data
 * @param data
 */
export const appendArrayToSearch: (
    data: {key: string; value: ReactText | null | undefined}[]
) => string | null = (data) => {
    if (!data || !data.length) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    const search = new URLSearchParams(history.location.search)
    data.map(({key, value}) => {
        if (value) {
            search.set(key, value.toString())
        } else {
            search.delete(key)
        }
    })
    oldSearch?.sort && oldSearch.sort()
    search?.sort && search.sort()

    if (search.toString() !== oldSearch.toString()) {
        pushToHistory(search.toString())
    }
    return search.toString()
}

/**
 * Delete from search params by search-key
 * @param key
 */
export const deleteFromSearch: (key: string) => string | null = (key) => {
    if (!key) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    const search = new URLSearchParams(history.location.search)
    search.delete(key)
    search.sort()
    console.log('deleteFromSearch: ', search.toString())
    if (search.toString() !== oldSearch.toString()) {
        pushToHistory(search.toString())
    }
    return search.toString()
}

/**
 * Delete item from search params array by key and value
 * @param key
 * @param value
 * @param optional
 */
export const deleteFromSearchArray: (
    key: string,
    value: string,
    optional?: {key: string; value: string}
) => string | null = (key, value, optional) => {
    if (!key) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    const search = new URLSearchParams(history.location.search)
    const all = search.getAll(`${key}[]`)
    search.delete(`${key}[]`)
    const newAll = all.filter((item) => item !== value)
    newAll.map((item) => {
        search.append(`${key}[]`, item)
    })
    if (optional) {
        search.set(optional.key, optional.value)
    }
    search.sort()
    console.log('deleteFromSearchArray: ', search.toString())
    if (search.toString() !== oldSearch.toString()) {
        pushToHistory(search.toString())
    }
    return search.toString()
}

/**
 * Massive delete parameter from search by array of keys
 * @param keys
 */
export const deleteParamsFromSearch: (keys: string[]) => string | null = (
    keys
) => {
    if (!keys.length) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    const search = new URLSearchParams(history.location.search)
    keys.map((key) => {
        search.delete(key)
    })
    search.sort()
    if (search.toString() !== oldSearch.toString()) {
        pushToHistory(search.toString())
    }
    return search.toString()
}

/**
 * Push to search params by search-key and data
 * @param key
 * @param data
 * @param optional
 */
export const pushToSearch: (
    key: string,
    data: string,
    optional?: {key: string; value: string}
) => string | null = (key, data, optional) => {
    if (!key || !data) {
        return null
    }
    const oldSearch = new URLSearchParams(history.location.search)
    const search = new URLSearchParams(history.location.search)
    search.append(`${key}[]`, data)
    if (optional) {
        search.set(optional.key, optional.value)
    }
    search.sort()
    if (search.toString() !== oldSearch.toString()) {
        pushToHistory(search.toString())
    }
    return search.toString()
}

/**
 * Convert to price with locale ru-RU
 * @param val
 */
export const toLocaleNumber: (val: number | string | null) => string = (
    val
) => {
    if (!val) {
        return '-'
    }
    if (typeof val === 'string') {
        return parseFloat(val).toLocaleString('ru-RU', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currency: 'RUB',
            style: 'currency',
        })
    } else {
        return val.toLocaleString('ru-RU', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currency: 'RUB',
            style: 'currency',
        })
    }
}

export function debounce(
    func: (v: string) => void,
    timeout = 300
): (v: string) => void {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            // eslint-disable-next-line no-invalid-this
            func.apply(this, args)
        }, timeout)
    }
}

export const pushToHistory = (search: string): void => {
    history.push({search})
}

/**
 * Custom hook useDebounce
 * @param value
 * @param timeout
 */
export const useDebounce: (value, timeout) => string | number = (
    value,
    timeout
) => {
    // Save a local copy of `value` in this state which is local to our hook
    const [state, setState] = useState(value)

    useEffect(() => {
        // Set timeout to run after delay
        const handler = setTimeout(() => setState(value), timeout)
        // clear the setTimeout listener on unMount
        return () => clearTimeout(handler)
    }, [value, timeout])

    return state
}

/**
 * Round a variable up to the next closest multiple of "multiple"
 * @param value
 * @param multiple
 * @param min
 * @param max
 */
export const nearestMultiple: (
    value: number | string,
    multiple: number,
    min?: number,
    max?: number
) => number = (value, multiple, min, max) => {
    const val = Math.ceil(+value / multiple) * multiple
    if (min && val < min) {
        return min
    }
    if (max && val > max) {
        return max
    }
    return val
}

export const removeDuplicateProducts = (products: IProduct[]): IProduct[] => {
    return products.reduce((prev: IProduct[], curr: IProduct) => {
        const prodIndex = prev.findIndex((el) => curr.id === el.id)
        if (prodIndex > -1) {
            prev[prodIndex].quantity = curr.quantity
            return prev
        } else {
            return [...prev, curr]
        }
    }, [])
}

/**
 * Gets param valvue from .env, checks if it is number
 * @param defaultValue default value of the parameter
 * @param envParameter parameter. Ex: process.env.REACT_APP_PARAM
 * @returns number as value of a param
 */
export const readEnvNumber = (
    defaultValue: number,
    envParameter?: string
): number => {
    const param = Number(envParameter)
    return isNaN(param) ? defaultValue : param
}

export function setDomainCookie(name, value, days, domain) {
    const expires = days
        ? `; expires=${new Date(
              Date.now() + days * 24 * 60 * 60 * 1000
          ).toUTCString()}`
        : ''

    document.cookie = `${name}=${value}${expires}; domain=.${domain}; path=/; secure; samesite=none;`
}
