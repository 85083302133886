import {
    FETCH_SECTION_CATEGORIES_START,
    FETCH_SECTION_CATEGORIES_SUCCESS,
    FETCH_SECTION_CATEGORIES_ERROR,
} from '../actions/actionTypes'

const initialState = {
    sectionCategories: [],
    loading: false,
    error: null,
}

export const sectionCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SECTION_CATEGORIES_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_SECTION_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                sectionCategories: action.payload,
            }
        case FETCH_SECTION_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
