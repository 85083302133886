// React
import React from 'react'

// Third-party
// import {lazy} from '@loadable/component'
// import pMinDelay from 'p-min-delay'

// Typescript
import {IRoute} from './IRoute'

// App
import AdminLoader from '../../UI/AdminLoader/AdminLoader'
import {retry} from './componentLoader'

export const routes: IRoute[] = [
    {
        name: 'Статические страницы',
        path: '/s/:slug',
        component: React.lazy(() =>
            retry(() => import('../../../pages/static/StaticPage'))
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'light',
        fallback: <AdminLoader />,
    },
    {
        name: 'Биржа',
        path: '/market/:slug1/:slug2',
        component: React.lazy(() =>
            retry(
                () => import('../../../pages/marketcategory/MarketCategory.jsx')
            )
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Предзаказ',
        path: '/preorder/:slug1/:slug2',
        component: React.lazy(() =>
            retry(
                () =>
                    import(
                        '../../../pages/preordercategory/PreorderCategory.jsx'
                    )
            )
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Биржа',
        path: '/market/:slug',
        component: React.lazy(() =>
            retry(
                () => import('../../../pages/marketcategories/MarketCategories')
            )
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Предзаказ',
        path: '/preorder/:slug',
        component: React.lazy(() =>
            retry(
                () =>
                    import(
                        '../../../pages/preorderCategories/PreorderCategories'
                    )
            )
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Биржа',
        path: '/market',
        component: React.lazy(() =>
            retry(() => import('../../../pages/market/Market'))
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Предзаказ',
        path: '/preorder',
        component: React.lazy(() =>
            retry(() => import('../../../pages/preorder/PreOrder.jsx'))
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'main',
        fallback: <AdminLoader />,
    },
    {
        name: 'Корзина',
        path: '/cart',
        title: 'Корзина | Crocus',
        description: 'Корзина',
        keywords: 'Корзина',
        component: React.lazy(() =>
            retry(() => import('../../../pages/cart/Cart'))
        ),
        hide: true,
        exact: false,
        private: false,
        bg: 'light',
        fallback: <AdminLoader title="Загрузка корзины" />,
    },
    {
        name: 'Личный кабинет',
        title: 'Личный кабинет | Crocus',
        description: 'Личный кабинет',
        keywords: 'Личный кабинет',
        path: '/lk',
        redirect: '/lk/orders',
        hide: true,
        exact: true,
        private: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Заказы',
        title: 'Заказы | Crocus',
        description: 'Личный кабинет, Заказы',
        keywords: 'Личный кабинет, Заказы',
        path: '/lk/orders',
        component: React.lazy(() =>
            retry(() => import('../../../pages/lk/Orders'))
        ),
        hide: true,
        exact: false,
        private: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Адрес',
        title: 'Адрес | Crocus',
        description: 'Личный кабинет, Адрес',
        keywords: 'Личный кабинет, Адрес',
        path: '/lk/address',
        component: React.lazy(() =>
            retry(() => import('../../../pages/lk/Address'))
        ),
        hide: true,
        exact: false,
        private: false,
        fallback: <AdminLoader />,
    },
    {
        name: 'Профиль',
        title: 'Профиль | Crocus',
        description: 'Личный кабинет, Профиль',
        keywords: 'Личный кабинет, Профиль',
        path: '/lk/profile',
        component: React.lazy(() =>
            retry(() => import('../../../pages/lk/Profile'))
        ),
        hide: true,
        exact: false,
        private: false,
        fallback: <AdminLoader />,
    },
    {
        name: 'Задолженность',
        title: 'Задолженность | Crocus',
        description: 'Личный кабинет, Задолженность',
        keywords: 'Личный кабинет, Задолженность',
        path: '/lk/debt',
        component: React.lazy(() =>
            retry(() => import('../../../pages/lk/Debt'))
        ),
        hide: true,
        exact: false,
        private: false,
        fallback: <AdminLoader />,
    },
    {
        name: 'Администрирование',
        title: 'Администрирование | Crocus',
        description: 'Администрирование',
        keywords: 'Администрирование',
        path: '/admin',
        redirect: '/admin/dashboard',
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Панель управления',
        title: 'Панель управления | Crocus',
        description: 'Панель управления',
        keywords: 'Панель управления',
        path: '/admin/dashboard',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Dashboard'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Статичные страницы',
        title: 'Статичные страницы | Crocus',
        description: 'Статичные страницы',
        keywords: 'Статичные страницы',
        path: '/admin/pages',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/StaticPages'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Статическая страница',
        title: 'Статическая страница | Crocus',
        description: 'Статическая страница',
        keywords: 'Статическая страница',
        path: '/admin/page/:slug',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/StaticPage'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Добавить новую страницу',
        title: 'Добавить новую страницу | Crocus',
        description: 'Добавить новую страницу',
        keywords: 'Добавить новую страницу',
        path: '/admin/pages/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddStaticPage'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Слайдер',
        title: 'Слайдер настройка | Crocus',
        description: 'Слайдер настройка',
        keywords: 'Слайдер настройка',
        path: '/admin/carousel',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Carousel'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Слайдер',
        title: 'Слайдер настройка | Crocus',
        description: 'Слайдер настройка',
        keywords: 'Слайдер настройка',
        path: '/admin/carousel/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddCarousel'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Слайдер',
        title: 'Слайдер настройка | Crocus',
        description: 'Слайдер настройка',
        keywords: 'Слайдер настройка',
        path: '/admin/carouselslide/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/CarouselSlide'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Категории',
        title: 'Категории | Crocus',
        description: 'Категории',
        keywords: 'Категории',
        path: '/admin/categories',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Categories'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },

    {
        name: 'Разделы',
        title: 'Разделы | Crocus',
        description: 'Разделы',
        keywords: 'Разделы',
        path: '/admin/sections',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Sections.jsx'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Категория',
        title: 'Категория | Crocus',
        description: 'Категория',
        keywords: 'Категория',
        path: '/admin/category/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Category'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Добавить новый раздел',
        title: 'Добавить новый раздел | Crocus',
        description: 'Добавить новый раздел',
        keywords: 'Добавить новый раздел',
        path: '/admin/sections/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddSection.jsx'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Раздел',
        title: 'Раздел | Crocus',
        description: 'Раздел',
        keywords: 'Раздел',
        path: '/admin/sections/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/EditSection.jsx'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Добавить новую категорию',
        title: 'Добавить новую категорию | Crocus',
        description: 'Добавить новую категорию',
        keywords: 'Добавить новую категорию',
        path: '/admin/categories/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddCategory'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Подкатегории',
        title: 'Подкатегории | Crocus',
        description: 'Подкатегории',
        keywords: 'Подкатегории',
        path: '/admin/subcategories',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/SubCategories'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Подкатегория',
        title: 'Подкатегория | Crocus',
        description: 'Подкатегория',
        keywords: 'Подкатегория',
        path: '/admin/subcategory/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/SubCategory'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Добавить новую подкатегорию',
        title: 'Добавить новую подкатегорию | Crocus',
        description: 'Добавить новую подкатегорию',
        keywords: 'Добавить новую подкатегорию',
        path: '/admin/subcategories/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddSubCategory'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Метки',
        title: 'Метки | Crocus',
        description: 'Метки',
        keywords: 'Метки',
        path: '/admin/badges',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Labels'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Продукты',
        title: 'Продукты | Crocus',
        description: 'Продукты',
        keywords: 'Продукты',
        path: '/admin/products',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Products'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Продукты',
        title: 'Добавить новый продукт | Crocus',
        description: 'Добавить новый продукт',
        keywords: 'Добавить новый продукт',
        path: '/admin/products/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddProduct'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Продукт',
        title: 'Продукт | Crocus',
        description: 'Продукт',
        keywords: 'Продукт',
        path: '/admin/product/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Product'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Пользователи',
        title: 'Пользователи | Crocus',
        description: 'Пользователи',
        keywords: 'Пользователи',
        path: '/admin/users',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Users'))
        ),
        hide: true,
        exact: true,
        private: true,
        admin: true,
        fallback: '',
    },
    {
        name: 'Пользователи',
        title: 'Добавить нового пользователя | Crocus',
        description: 'Добавить нового пользователя',
        keywords: 'Добавить нового пользователя',
        path: '/admin/users/add',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/AddUser'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Пользователь',
        title: 'Пользователь | Crocus',
        description: 'Пользователь',
        keywords: 'Пользователь',
        path: '/admin/user/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/User'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Заказы',
        title: 'Заказы | Crocus',
        description: 'Заказы',
        keywords: 'Заказы',
        path: '/admin/orders',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Orders'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Заказ',
        title: 'Заказ | Crocus',
        description: 'Заказ',
        keywords: 'Заказ',
        path: '/admin/order/:id',
        component: React.lazy(() =>
            retry(() => import('../../../pages/admin/Order'))
        ),
        hide: true,
        exact: false,
        private: true,
        admin: true,
        fallback: <AdminLoader />,
    },
    {
        name: 'Спасибо за заказ',
        title: 'Спасибо за заказ | Crocus',
        description: 'Спасибо за заказ',
        keywords: 'Спасибо за заказ',
        path: '/thank-you',
        component: React.lazy(() =>
            retry(() => import('../../../pages/thankyoupage/ThankYouPage'))
        ),
        hide: true,
        exact: false,
        private: false,
        fallback: <AdminLoader title="Формируем заказ" />,
        bg: 'light',
    },
    {
        name: 'Оформление заказа для менеджера',
        title: 'Оформление заказа для менеджера | Crocus',
        description: 'Оформление заказа для менеджера',
        keywords: 'Оформление заказа для менеджера',
        path: '/checkoutmanager',
        component: React.lazy(() =>
            retry(
                () => import('../../../pages/checkoutmanager/CheckoutManager')
            )
        ),
        hide: true,
        exact: false,
        private: false,
        fallback: <AdminLoader />,
    },
]
