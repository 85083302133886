// @ts-nocheck
import {ISubCategoriesActionTypes, ISubCategoriesState} from '../../models/subCategories'

const initialState: ISubCategoriesState = {
    subCategories: [],
    subCategory: null,
    stock: null,
    activeSubCategory: null,
    activeSubCategoryType: null,
    loading: true,
    error: null
}

export const subCategoriesReducer: (state: ISubCategoriesState, action: ISubCategoriesActionTypes) => void = (
    state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SUB_CATEGORIES_START':
            return {
                ...state, loading: true, error: null
            }
        case 'FETCH_SUB_CATEGORIES_SUCCESS':
            return {
                ...state, loading: false, subCategories: action.payload, error: null
            }
        case 'FETCH_SUB_CATEGORIES_ERROR':
            return {
                ...state, loading: false, error: action.payload
            }
        case 'SET_ACTIVE_SUB_CATEGORY_TYPE':
            return {
                ...state, activeSubCategoryType: action.payload
            }
        case 'SET_ACTIVE_SUB_CATEGORY': {
            let activeSubCategory = state.subCategories.find(({id}) => id === action.payload) || null
            if (!action.payload) {
                activeSubCategory = null
            }
            return {
                ...state, activeSubCategory
            }
        }
        case 'DELETE_SUB_CATEGORY_SUCCESS':
            return {
                ...state,
                loading: false,
                categories: state.subCategories.filter(({id}) => id !== action.payload),
                error: null
            }
        case 'FETCH_SUB_CATEGORY_START':
        case 'CREATE_SUB_CATEGORY_START':
        case 'UPDATE_SUB_CATEGORY_START':
        case 'FETCH_SUB_CATEGORY_SUCCESS':
        case 'CREATE_SUB_CATEGORY_SUCCESS':
        case 'UPDATE_SUB_CATEGORY_SUCCESS':
            return {
                ...state, loading: false, subCategory: action.payload, error: null
            }
        case 'FETCH_SUB_CATEGORY_ERROR':
        case 'CREATE_SUB_CATEGORY_ERROR':
        case 'UPDATE_SUB_CATEGORY_ERROR':
        default:
            return state
    }
}
