// PRODUCTS
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_ADMIN_SUCCESS = 'FETCH_PRODUCTS_ADMIN_SUCCESS'
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START'
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR'

export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR'

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'

export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'

export const REPLICATE_PRODUCT_SUCCESS = 'REPLICATE_PRODUCT_SUCCESS'

export const SET_PRODUCT_FILTERS_START = 'SET_PRODUCT_FILTERS_START'
export const SET_PRODUCT_FILTERS_DISABLED = 'SET_PRODUCT_FILTERS_DISABLED'
export const SET_PRODUCT_FILTERS_SUCCESS = 'SET_PRODUCT_FILTERS_SUCCESS'

export const FETCH_LABELS_START = 'FETCH_LABELS_START'
export const FETCH_LABELS_SUCCESS = 'FETCH_LABELS_SUCCESS'
export const FETCH_LABELS_ERROR = 'FETCH_LABELS_ERROR'

export const DELETE_LABELS_SUCCESS = 'DELETE_LABELS_SUCCESS'
export const DELETE_LABELS_ERROR = 'DELETE_LABELS_ERROR'

export const UPDATE_LABELS_SUCCESS = 'UPDATE_LABELS_SUCCESS'
export const UPDATE_LABELS_ERROR = 'UPDATE_LABELS_ERROR'

export const CREATE_LABELS_SUCCESS = 'CREATE_LABELS_SUCCESS'
export const CREATE_LABELS_ERROR = 'CREATE_LABELS_ERROR'

export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS'
export const CLEAR_PRODUCT_FILTERS = 'CLEAR_PRODUCT_FILTERS'

// CATEGORIES
export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR'

export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START'
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR'

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR'

export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_PRODUCTS_SUCCESS =
    'DELETE_CATEGORY_PRODUCTS_SUCCESS'

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'

export const FETCH_STOCK_START = 'FETCH_STOCK_START'
export const FETCH_STOCK_SUCCESS = 'FETCH_STOCK_SUCCESS'
export const FETCH_STOCK_ERROR = 'FETCH_STOCK_ERROR'

export const CHANGE_STOCK_SUCCESS = 'CHANGE_STOCK_SUCCESS'
export const CHANGE_STOCK_ERROR = 'CHANGE_STOCK_ERROR'

export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY'
export const SET_ACTIVE_CATEGORY_TYPE = 'SET_ACTIVE_CATEGORY_TYPE'

// SUBCATEGORIES

export const FETCH_SUB_CATEGORIES_START = 'FETCH_SUB_CATEGORIES_START'
export const FETCH_SUB_CATEGORIES_SUCCESS = 'FETCH_SUB_CATEGORIES_SUCCESS'
export const FETCH_SUB_CATEGORIES_ERROR = 'FETCH_SUB_CATEGORIES_ERROR'

export const FETCH_SUB_CATEGORY_START = 'FETCH_SUB_CATEGORY_START'
export const FETCH_SUB_CATEGORY_SUCCESS = 'FETCH_SUB_CATEGORY_SUCCESS'
export const FETCH_SUB_CATEGORY_ERROR = 'FETCH_SUB_CATEGORY_ERROR'

export const CREATE_SUB_CATEGORY_START = 'CREATE_SUB_CATEGORY_START'
export const CREATE_SUB_CATEGORY_SUCCESS = 'CREATE_SUB_CATEGORY_SUCCESS'
export const CREATE_SUB_CATEGORY_ERROR = 'CREATE_SUB_CATEGORY_ERROR'

export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS'
export const DELETE_SUB_CATEGORY_PRODUCTS_SUCCESS =
    'DELETE_SUB_CATEGORY_PRODUCTS_SUCCESS'

export const UPDATE_SUB_CATEGORY_START = 'UPDATE_SUB_CATEGORY_START'
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS'
export const UPDATE_SUB_CATEGORY_ERROR = 'UPDATE_SUB_CATEGORY_ERROR'

export const SET_ACTIVE_SUB_CATEGORY = 'SET_ACTIVE_SUB_CATEGORY'
export const SET_ACTIVE_SUB_CATEGORY_TYPE = 'SET_ACTIVE_SUB_CATEGORY_TYPE'

// CART
export const FETCH_CART_START = 'FETCH_CART_START'
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS'
export const FETCH_CART_ERROR = 'FETCH_CART_ERROR'

export const CHANGE_CART_START = 'CHANGE_CART_START'
export const CHANGE_CART_SUCCESS = 'CHANGE_CART_SUCCESS'
export const CHANGE_CART_BY_MANAGER_SUCCESS = 'CHANGE_CART_BY_MANAGER_SUCCESS'
export const CHANGE_ORDER_CART_SUCCESS = 'CHANGE_ORDER_CART_SUCCESS'
export const CHANGE_CART_ERROR = 'CHANGE_CART_ERROR'

export const SET_RETURN_VALUE = 'SET_RETURN_VALUE'

export const SET_EDIT_CART = 'SET_EDIT_CART'

export const ADD_TO_CART_START = 'ADD_TO_CART_START'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR'

export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'

export const SET_EDIT_ORDER_ID = 'SET_EDIT_ORDER_ID'

// CHECKOUT
export const FETCH_CHECKOUT_START = 'FETCH_CHECKOUT_START'
export const FETCH_CHECKOUT_SUCCESS = 'FETCH_CHECKOUT_SUCCESS'
export const FETCH_CHECKOUT_ERROR = 'FETCH_CHECKOUT_ERROR'

export const CREATE_CHECKOUT_START = 'CREATE_CHECKOUT_START'
export const CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS'
export const CREATE_CHECKOUT_ERROR = 'CREATE_CHECKOUT_ERROR'

// ORDERS
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'

export const FETCH_ORDER_START = 'FETCH_ORDER_START'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR'

export const FETCH_ADMIN_ORDERS_START = 'FETCH_ADMIN_ORDERS_START'
export const FETCH_ADMIN_ORDERS_SUCCESS = 'FETCH_ADMIN_ORDERS_SUCCESS'
export const FETCH_ADMIN_ORDERS_ERROR = 'FETCH_ADMIN_ORDERS_ERROR'

export const CREATE_ORDER_START = 'CREATE_ORDER_START'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR'

// USERS
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const FETCH_USER_START = 'FETCH_USER_START'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const UPDATE_STAFF_START = 'UPDATE_STAFF_START'
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS'
export const UPDATE_STAFF_ERROR = 'UPDATE_STAFF_ERROR'

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR'

export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS'
export const ADD_STAFF_ERROR = 'ADD_STAFF_ERROR'

// STATIC
export const FETCH_PAGES_START = 'FETCH_PAGES_START'
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS'
export const FETCH_PAGES_ERROR = 'FETCH_PAGES_ERROR'

export const FETCH_PAGE_START = 'FETCH_PAGE_START'
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS'
export const FETCH_PAGE_ERROR = 'FETCH_PAGE_ERROR'

export const CREATE_PAGE_START = 'CREATE_PAGE_START'
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_ERROR = 'CREATE_PAGE_ERROR'

export const UPDATE_PAGE_START = 'UPDATE_PAGE_START'
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_ERROR = 'UPDATE_PAGE_ERROR'

export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'

export const FETCH_CAROUSEL_START = 'FETCH_CAROUSEL_START'
export const FETCH_CAROUSEL_SUCCESS = 'FETCH_CAROUSEL_SUCCESS'
export const FETCH_CAROUSEL_ERROR = 'FETCH_CAROUSEL_ERROR'

export const FETCH_CAROUSELBYID_START = 'FETCH_CAROUSELBYID_START'
export const FETCH_CAROUSELBYID_SUCCESS = 'FETCH_CAROUSELBYID_SUCCESS'
export const FETCH_CAROUSELBYID_ERROR = 'FETCH_CAROUSELBYID_ERROR'

export const CREATE_CAROUSEL_START = 'CREATE_CAROUSEL_START'
export const CREATE_CAROUSEL_SUCCESS = 'CREATE_CAROUSEL_SUCCESS'
export const CREATE_CAROUSEL_ERROR = 'CREATE_CAROUSEL_ERROR'

export const DELETE_CAROUSEL_ITEM_SUCCESS = 'DELETE_CAROUSEL_ITEM_SUCCESS'

export const UPDATE_CAROUSEL_START = 'UPDATE_CAROUSEL_START'
export const UPDATE_CAROUSEL_SUCCESS = 'UPDATE_CAROUSEL_SUCCESS'
export const UPDATE_CAROUSEL_ERROR = 'UPDATE_CAROUSEL_ERROR'

export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_START = 'FETCH_CONTACTS_START'
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR'
export const UPDATE_CONTACTS_SUCCESS = 'UPDATE_CONTACTS_SUCCESS'

export const IMPORT_FROM_EXCEL_START = 'IMPORT_FROM_EXCEL_START'
export const IMPORT_FROM_EXCEL_SUCCESS = 'IMPORT_FROM_EXCEL_SUCCESS'
export const IMPORT_FROM_EXCEL_ERROR = 'IMPORT_FROM_EXCEL_ERROR'

// SLUGS

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_PAGE_SLUG = 'SET_PAGE_SLUG'

// SECTIONS

export const FETCH_SECTIONS_START = 'FETCH_SECTIONS_START'
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS'
export const FETCH_SECTIONS_ERROR = 'FETCH_SECTIONS_ERROR'

// SELECTED SECTION

export const FETCH_SECTION_SELECTED_START = 'FETCH_SECTION_SELECTED_START'
export const FETCH_SECTION_SELECTED_SUCCESS = 'FETCH_SECTION_SELECTED_SUCCESS'
export const FETCH_SECTION_SELECTED_ERROR = 'FETCH_SECTION_SELECTED_ERROR'

export const CREATE_SECTION_START = 'CREATE_SECTION_START'
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS'
export const CREATE_SECTION_ERROR = 'CREATE_SECTION_ERROR'

export const UPDATE_SECTION_START = 'UPDATE_SECTION_START'
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS'
export const UPDATE_SECTION_ERROR = 'UPDATE_SECTION_ERROR'

export const FETCH_SECTION_CATEGORIES_START = 'FETCH_SECTION_CATEGORIES_START'
export const FETCH_SECTION_CATEGORIES_SUCCESS =
    'FETCH_SECTION_CATEGORIES_SUCCESS'
export const FETCH_SECTION_CATEGORIES_ERROR = 'FETCH_SECTION_CATEGORIES_ERROR'

export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'
