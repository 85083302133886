// @ts-nocheck
import {IUser, IUserState, UsersActionTypes} from '../../models/users'

const initialState: IUserState = {
    users: [],
    totalUsers: 0,
    user: null,
    loading: true,
    error: null
}

export const usersReducer: (state: IUserState, action: UsersActionTypes) => void = (
    state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USER_START':
        case 'FETCH_USERS_START':
        case 'UPDATE_STAFF_START':
            return {
                ...state, loading: true
            }
        case 'FETCH_USERS_SUCCESS':
            return {
                ...state, users: action.payload.users, totalUsers: action.payload.count, loading: false
            }
        case 'UPDATE_STAFF_SUCCESS':
        case 'FETCH_USER_SUCCESS':
            return {
                ...state, user: action.payload, loading: false
            }
        case 'FETCH_USER_ERROR':
        case 'FETCH_USERS_ERROR':
        case 'UPDATE_STAFF_ERROR':
            return {
                ...state, error: action.payload, loading: false
            }
        case 'DELETE_USER_SUCCESS':
            return {
                ...state, loading: false
            }
        case 'ACTIVATE_USER_SUCCESS': {
            const newUsers = [...state.users]
            newUsers[newUsers.findIndex(el => el.id === action.payload.id)] = action.payload
            return {
                ...state, loading: false, users: [...newUsers]
            }
        }
        case 'CHANGE_CART_BY_MANAGER_SUCCESS': {
            const newUser = {...state.user} as IUser
            newUser.cart = action.payload
            return {
                ...state, loading: false, user: newUser
            }
        }
        default:
            return state
    }
}
