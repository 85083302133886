// @ts-nocheck
import {IStaticActionTypes, IStaticState} from '../../models/static'

const initialState: IStaticState = {
    pages: [],
    page: null,
    carousel: [],
    carouselSlide: null,
    contacts: null,
    loading: false,
    error: null
}

export const staticReducer: (state: IStaticState, action: IStaticActionTypes) => void = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case 'FETCH_PAGES_START':
        case 'FETCH_PAGE_START':
        case 'FETCH_CAROUSEL_START':
        case 'FETCH_CAROUSELBYID_START':
        case 'CREATE_PAGE_START':
        case 'UPDATE_CAROUSEL_START':
        case 'UPDATE_PAGE_START':
        case 'FETCH_CONTACTS_START':
            return {
                ...state,
                loading: true,
                error: null
            }
        case 'FETCH_PAGES_SUCCESS':
            return {
                ...state,
                loading: false,
                pages: action.payload,
                error: null
            }
        case 'FETCH_PAGE_SUCCESS':
        case 'CREATE_PAGE_SUCCESS':
        case 'UPDATE_PAGE_SUCCESS':
            return {
                ...state,
                loading: false,
                page: action.payload,
                error: null
            }
        case 'FETCH_CAROUSEL_ERROR':
        case 'FETCH_PAGES_ERROR':
        case 'FETCH_PAGE_ERROR':
        case 'CREATE_PAGE_ERROR':
        case 'UPDATE_CAROUSEL_ERROR':
        case 'UPDATE_PAGE_ERROR':
        case 'FETCH_CAROUSELBYID_ERROR':
        case 'FETCH_CONTACTS_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case 'FETCH_CAROUSELBYID_SUCCESS':
            return {
                ...state,
                loading: false,
                carouselSlide: action.payload,
                error: null
            }
        case 'FETCH_CAROUSEL_SUCCESS':
        case 'UPDATE_CAROUSEL_SUCCESS':
            return {
                ...state,
                loading: false,
                carousel: action.payload,
                error: null
            }
        case 'FETCH_CONTACTS_SUCCESS':
        case 'UPDATE_CONTACTS_SUCCESS':
            return {
                ...state,
                loading: false,
                contacts: action.payload,
                error: null
            }
        case 'IMPORT_FROM_EXCEL_START':
            return {...state, importLoading: true}
        case 'IMPORT_FROM_EXCEL_SUCCESS':
            return {...state, importLoading: false}
        case 'IMPORT_FROM_EXCEL_ERROR':
            return {...state, importLoading: false, importError: action.payload}

        default:
            return state
    }
}
