const numberOfAttempts = Number(process.env.MIX_FRONT_NUMBER_OF_LOAD_ATTEMPTS || 10)

export function retry<T>(fn: () => Promise<T>, retriesLeft = numberOfAttempts, interval = 1000): Promise<T> {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch(error => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        reject(error)
                        return
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject)
                }, interval)
            })
    })
}
