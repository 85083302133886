import React, {Suspense} from 'react'
import './sass/app.scss'

// Third-party
import {Router as ReactRouter} from 'react-router'
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction'
import {createBrowserHistory} from 'history'
import {AnimateSharedLayout} from 'framer-motion'
import {ToastContainer} from 'react-toastify'
import Helmet from 'react-helmet'
// App
import Router from './components/navigation/router/Router'
import {routes} from './components/navigation/router/routes'
import CloseButton from './components/UI/Toastify/CloseButton'
import rootReducer from './store/reducers/rootReducer'
import Responsive from './ResponsiveContext/Responsive'
import Sanctum from './Sanctum/Sanctum'
import AdminLoader from './components/UI/AdminLoader/AdminLoader'
import {loadState, saveState} from './localStorage.js'

const middleware = thunk

const persistedState = loadState()

const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(middleware))
)

store.subscribe(() => {
    saveState({
        slugsState: store.getState().slugsState,
        sectionState: store.getState().sectionState,
        selectedSectionState: store.getState().selectedSectionState,
        staticState: store.getState().staticState,
        categoriesState: store.getState().categoriesState,
    })
})

export const history = createBrowserHistory()

export const sanctumConfig = {
    apiUrl: process.env.REACT_APP_API_URL || 'https://api.crocus.dev-2-tech.ru',
    csrfCookieRoute:
        process.env.REACT_APP_CSRF_COOKIE_ROUTE || 'sanctum/csrf-cookie',
    signInRoute: process.env.REACT_APP_SIGNIN_ROUTE || 'api/login',
    signUpRoute: process.env.REACT_APP_SIGNUP_ROUTE || 'api/register',
    signOutRoute: process.env.REACT_APP_SIGNOUT_ROUTE || 'api/logout',
    forgotPasswordRoute:
        process.env.REACT_APP_FORGOT_PASSWORD_ROUTE || 'api/forgot',
    resetPasswordRoute:
        process.env.REACT_APP_RESET_PASSWORD_ROUTE || 'api/reset',
    userObjectRoute: process.env.REACT_APP_USER_OBJECT_ROUTE || 'api/user',
    domain: process.env.REACT_APP_DOMAIN || 'crocus.dev-2-tech.ru',
}

function App() {
    return (
        <Suspense fallback={<AdminLoader />}>
            <Sanctum checkOnInit={false} config={sanctumConfig}>
                <Responsive>
                    <Provider store={store}>
                        <ReactRouter history={history}>
                            <AnimateSharedLayout type="crossfade">
                                <Helmet>
                                    <html lang="ru" />
                                    <title>Срезка в наличии | Crocus</title>
                                    <meta
                                        name="description"
                                        content="Срезка в наличии"
                                    />
                                    <meta
                                        name="keywords"
                                        content="Срезка в наличии"
                                    />
                                </Helmet>
                                <Router routes={routes} />
                                <ToastContainer
                                    position="top-right"
                                    autoClose={3000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    closeButton={({closeToast}) => (
                                        <CloseButton closeToast={closeToast} />
                                    )}
                                    rtl={false}
                                    pauseOnFocusLoss
                                    limit={3}
                                    draggablePercent={20}
                                    draggable
                                    pauseOnHover
                                />
                            </AnimateSharedLayout>
                        </ReactRouter>
                    </Provider>
                </Responsive>
            </Sanctum>
        </Suspense>
    )
}

export default App
