import {
    CREATE_SECTION_ERROR,
    CREATE_SECTION_START,
    CREATE_SECTION_SUCCESS,
    FETCH_SECTIONS_ERROR,
    FETCH_SECTIONS_START,
    FETCH_SECTIONS_SUCCESS,
    UPDATE_SECTION_SUCCESS,
    UPDATE_SECTION_START,
    UPDATE_SECTION_ERROR,
} from '../actions/actionTypes'

const initialState = {
    sections: [],
    sectionsAll: [],
    loading: false,
    error: null,
}

export const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SECTION_START:
        case FETCH_SECTIONS_START:
        case UPDATE_SECTION_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_SECTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                sections: action.payload,
                sectionsAll: [...state.sections, ...action.payload],
            }
        case CREATE_SECTION_ERROR:
        case FETCH_SECTIONS_ERROR:
        case UPDATE_SECTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CREATE_SECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                sections: [action.payload, ...state.sections],
                error: null,
            }
        case UPDATE_SECTION_SUCCESS:
        default:
            return state
    }
}
