import {ICartActionsType, ICartState} from '../../models/carts'

const initialState: ICartState = {
    cart: null,
    cartTotal: null,
    timeAddFirstItem: null,
    loading: true,
    disabled: false,
    error: null,
    userId: null,
    returnValue: false
}

export const cartReducer: (state: ICartState, action: ICartActionsType) => void = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CART_START':
            return {
                ...state,
                loading: true,
                error: null
            }
        case 'FETCH_CART_SUCCESS':
            return {
                ...state,
                loading: false,
                cart: action.payload,
                error: null
            }
        case 'FETCH_CART_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case 'CHANGE_CART_START':
            return {
                ...state
            }
        case 'CHANGE_CART_SUCCESS':
            return {
                ...state,
                loading: false,
                cart: action.payload,
                cartTotal: action.payload.amount,
                timeAddFirstItem: action.payload.timeAddFirstItem,
                error: null
            }
        case 'CHANGE_CART_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case 'SET_RETURN_VALUE':
            return {
                ...state,
                returnValue: action.payload
            }
        case 'ADD_TO_CART_START':
            return {
                ...state,
                disabled: true,
                error: null
            }
        case 'ADD_TO_CART_SUCCESS':
            return {
                ...state,
                disabled: false,
                cartTotal: action.payload.amount,
                timeAddFirstItem: action.payload.timeAddFirstItem,
                cart: action.payload,
                error: null
            }
        case 'ADD_TO_CART_ERROR':
            return {
                ...state,
                disabled: false
            }
        case 'CREATE_CHECKOUT_START':
            return {
                ...state,
                loading: true,
                error: null
            }
        case 'CREATE_CHECKOUT_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null
            }
        case 'CREATE_CHECKOUT_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case 'SET_EDIT_CART':
            return {
                ...state,
                userId: action.payload
            }
        default:
            return state
    }
}
